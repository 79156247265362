import React, { useEffect, useState } from "react";
import ReactDOM from "react-dom";
import CountDown from "./components/Countdown/Timer";
import Preloader from "./components/Preloader/Preloader";

import "./styles.css";

function App() {

  const words = ["conception", "développement", "déploiement"];
  let i = 0;

  const [text, setText] = useState("run");

  useEffect(() => {
    const interval = setInterval(() => {
      i++;
      if(i > 2) i = 0;
      setText(words[i]);
    }, 1500);
    return () => clearInterval(interval);
  }, []);

  return (
    <div className="App">
      <div className="container">
        <h1>
          Ce site web est actuellement
          <br />
          en <b><u>{text}</u></b>
        </h1>

        <h2>
          Il sera accessible dans
        </h2>
        <CountDown />
        <Preloader />
      </div>
    </div>
  );
}

const rootElement = document.getElementById("root");
ReactDOM.render(<App />, rootElement);

export default App;